import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    bg: 'blue.900',
    '--popper-arrow-bg': 'var(--chakra-colors-blue-900)',
    textColor: 'white',
    _dark: {
      bg: 'gray.50',
      '--popper-arrow-bg': 'var(--chakra-colors-gray-50)',
      textColor: 'gray.900'
    },
    padding: 2,
    borderRadius: 8
  }
}
