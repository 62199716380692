import { Flex, HStack, Stack } from '@chakra-ui/react'

import { DsbomPackages } from '../dsbom-packages/DsbomPackages'

import { useGetMdsbomDerivedReportSetQuery, useGetMdsbomImageRepoQuery } from '../../redux/api/mdsbom'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { DsbomReportHeader } from './DsbomReportHeader'

import { DsbomReportBigStats } from './DsbomReportBigStats'

import { DsbomDefectsList } from '@/features/dsbom-defects/DsbomDefectsList'

import { Page } from '@/components/Page'
import { DataTab, DataTabs } from '@/components/DataTabs'
import { DsbomReportDownloadMenuButton } from '@/features/dsbom-reports/DsbomReportDownloadMenuButton'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

interface Props {
  workspaceSlug: string
  imageRepoId: string
  reportId: string
}

export function DsbomReportPage({ workspaceSlug, imageRepoId, reportId }: Props) {
  const { data: imageRepoData, isLoading: isImageLoading } = useGetMdsbomImageRepoQuery({ workspace: workspaceSlug, imageRepoId })
  const imageRepoName = imageRepoData?.image_name

  const {
    isError,
    error,
    isLoading: isReportSetLoading
  } = useGetMdsbomDerivedReportSetQuery({
    workspace: workspaceSlug,
    reportId
  })

  if (isError && error) {
    const goBackLocation = `/${workspaceSlug}/-/dynamic-sbom`
    if ('status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified MDSBOM report" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the MDSBOM report" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the MDSBOM report" goBackLocation={goBackLocation} />
  }

  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <DsbomDefectsList workspaceSlug={workspaceSlug} imageRepoId={imageRepoId} reportId={reportId} />,
      route: `/${workspaceSlug}/-/dynamic-sbom/${imageRepoId}/${reportId}`
    },
    {
      label: 'Packages',
      content: <DsbomPackages workspaceSlug={workspaceSlug} imageRepoId={imageRepoId} reportId={reportId} />,
      route: `/${workspaceSlug}/-/dynamic-sbom/${imageRepoId}/${reportId}/-/packages`
    }
  ]

  return (
    <Page
      header={
        <HStack>
          <MayhemBreadcrumb
            isLoading={isImageLoading}
            items={[
              { text: 'Dynamic SBOM', path: `/${workspaceSlug}/-/dynamic-sbom` },
              { text: imageRepoName || '', path: `/${workspaceSlug}/-/dynamic-sbom/${imageRepoId}`, isBadge: true, badgeTooltip: 'Image' },
              { text: 'Dynamic SBOM Report' }
            ]}
          />
          {!isImageLoading && <DsbomReportDownloadMenuButton workspaceSlug={workspaceSlug} reportId={reportId} />}
        </HStack>
      }
      isLoading={isReportSetLoading}
    >
      <Stack spacing={4}>
        <DsbomReportHeader workspaceSlug={workspaceSlug} reportId={reportId} />
        <Flex gap={4} wrap="wrap">
          <DsbomReportBigStats workspaceSlug={workspaceSlug} imageRepoId={imageRepoId} reportId={reportId} />
        </Flex>
        <DataTabs isLazy variant="line" width="full" tabs={tabData} />
      </Stack>
    </Page>
  )
}
