import { Button, Td, Tr, useToast } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { useQuery } from '../../hooks'

import { cleanInteger } from '../../util/numbers'

import { setPageQueryParam } from '../../util/location'

import { useDeleteApiTokenMutation } from '@/redux/api/apiTokens'

import { prettyFullTimeString } from '@/util/time'
interface Props {
  userSlug: string
  token: string
  name?: string
  lastAccess?: string
  isLastTokenInPage?: boolean
  pagePrefix?: string
}

export function ApiToken({ token, userSlug, name, lastAccess, isLastTokenInPage = false, pagePrefix = '' }: Props) {
  const [deleteTokenTrigger, { isLoading }] = useDeleteApiTokenMutation()
  const query = useQuery()
  const [location, setLocation] = useLocation()
  const page = query.get(`${pagePrefix}page`) || '1'

  const toast = useToast()

  const revoke = () => {
    deleteTokenTrigger({ userSlug, token: token })
      .unwrap()
      .then((_) => {
        toast({
          title: 'API token deleted',
          description: "We've deleted the requested api token.",
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        if (isLastTokenInPage) {
          // In case this was the last token on the page we need to update the page to show the next one
          const newPage = Math.max(1, cleanInteger(page) - 1)
          setLocation(setPageQueryParam(location, query, newPage, pagePrefix))
        }
      })
      .catch((_) => {
        toast({
          title: 'API token deletion error',
          description: 'There was an error deleting this api token.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  return (
    <Tr key={token}>
      <Td>{name}</Td>
      <Td>{lastAccess ? prettyFullTimeString(lastAccess) : 'Never Accessed'}</Td>
      <Td textAlign="center">
        <Button colorScheme="red" variant="outline" onClick={revoke} isLoading={isLoading} aria-label={`Revoke ${name}`}>
          Revoke
        </Button>
      </Td>
    </Tr>
  )
}
