import { useEffect } from 'react'
import { Heading, Stack } from '@chakra-ui/react'

import { getProjectDefectsPageTitle } from '../../titles'
import { splitCommaDelimitedParam } from '../../util/location'

import { useQuery } from '../../hooks'
import ProjectDefectsHeader from '../defect/ProjectDefectsHeader'
import { ProjectDefectsList } from '../defect/ProjectDefectsList'

import { useGetDefectStatsByProjectQuery } from '../../redux/api/defects'

import { Page } from '@/components/Page'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'
import { useGetProjectQuery } from '@/redux/api/projects'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectDefectsPage(props: Props) {
  const { workspaceSlug, projectSlug } = props
  const query = useQuery()

  const targetFilter = splitCommaDelimitedParam(query.get('target'))
  const runFilter = query.get('run') || 'all'
  const defectFilter = query.get('type') || 'all'

  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({ owner: workspaceSlug, projectSlug })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectDefectsPageTitle(workspaceName, projectName)
    }
  }, [workspaceName, projectName, targetFilter, runFilter, defectFilter])

  const { isLoading: isFetchingDefectsStats, data: defectStats } = useGetDefectStatsByProjectQuery({
    owner: workspaceSlug,
    projectSlug,
    target: targetFilter,
    run: runFilter === 'all' ? undefined : runFilter
  })

  // currently "active" defects mean the defects from the latest run
  const defectSet = runFilter === 'latest' ? defectStats?.active : defectStats?.total
  const defectTypes = defectSet?.breakdown.map((entry) => {
    return { name: entry.cwe_short_name, count: entry.count, cweNumber: entry.cwe_number, fullName: entry.cwe_name }
  })

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            {
              text: 'Defects'
            }
          ]}
        />
      }
    >
      <Heading mb={8}>Defects</Heading>
      <Stack spacing={8}>
        <ProjectDefectsHeader nDefects={defectSet?.count} defectTypes={defectTypes} isFetching={isFetchingDefectsStats} defectFilter={defectFilter} />
        <ProjectDefectsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
      </Stack>
    </Page>
  )
}
