import { useEffect } from 'react'
import { Heading, SimpleGrid } from '@chakra-ui/react'

import { getProjectPageTitle } from '../../titles'
import { CardDefectTrend } from '../../components/CardDefectTrend'

import { useGetProjectQuery } from '../../redux/api/projects'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { ProjectOverviewStats } from './ProjectOverviewStats'

import { Page } from '@/components/Page'
import { ProjectTargetsList } from '@/features/target/TargetsForProjectList'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectOverviewPage({ workspaceSlug, projectSlug }: Props) {
  const {
    data: project,
    isLoading,
    isError,
    error
  } = useGetProjectQuery({
    owner: workspaceSlug,
    projectSlug
  })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectPageTitle(workspaceName, projectName)
    }
  }, [workspaceName, projectName])

  if (isError && error) {
    const goBackLocation = `/${workspaceSlug}`
    if ('status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified project" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the project" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the project" goBackLocation={goBackLocation} />
  }

  return (
    <Page
      isLoading={isLoading}
      header={<MayhemBreadcrumb isLoading={isLoading} items={[{ text: 'Projects', path: `/${workspaceSlug}/-/projects` }, { text: projectName! }]} />}
    >
      <Heading mb={8}>{projectName}</Heading>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gap={6} mb={8}>
        <CardDefectTrend workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
        <ProjectOverviewStats workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
      </SimpleGrid>

      <ProjectTargetsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
    </Page>
  )
}
