import { useEffect } from 'react'
import { Heading } from '@chakra-ui/react'

import { getProjectBuildsPageTitle } from '../../titles'

import { ProjectBuildsList } from '../project-builds/ProjectBuildsList'

import { Page } from '@/components/Page'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'
import { useGetProjectQuery } from '@/redux/api/projects'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectBuildsPage({ workspaceSlug, projectSlug }: Props) {
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({
    owner: workspaceSlug,
    projectSlug
  })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectBuildsPageTitle(workspaceName, projectName)
    }
  }, [workspaceName, projectName])

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            { text: 'Builds' }
          ]}
        />
      }
    >
      <Heading mb={8}>Builds</Heading>
      <ProjectBuildsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
    </Page>
  )
}
