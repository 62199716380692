import { get } from '../../api'
import { AppThunk } from '../../redux/store'
import { clearMruWorkspace } from '../workspace-dashboard/mru.slice'
import { checkAuthentication, fetchApiInfo, fetchMayhemInfo } from '../config/actions'

import { receiveJwtToken, ReceiveJwtTokenAction, ReceiveKeycloakAction, ReceiveMeAction } from '../../redux/fetchMe'

import UserService from './UserService'

export type AuthAction = ReceiveMeAction | ReceiveKeycloakAction | ReceiveJwtTokenAction

export function logout(): AppThunk<Promise<unknown>> {
  return async (dispatch) => {
    await dispatch(clearMruWorkspace())

    if (UserService.isLoggedInToKeycloak()) {
      await UserService.doLogoutFromKeycloak()
    } else {
      await dispatch(fetchApiInfo())
      await dispatch(fetchMayhemInfo())
      await dispatch(checkAuthentication())
    }
  }
}

interface JwtTokenApiResponse {
  token: string
}

export function getJwtToken(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    await UserService.initKeycloak()
    try {
      const resp = await get<JwtTokenApiResponse>('/api/v2/user/jwt?include_mayhem_claims=true')
      dispatch(receiveJwtToken(resp.token))
    } catch (err) {
      // The api is not yet configured so the request fails.
      // We supress the error here, to prevent a crash
    }
  }
}
