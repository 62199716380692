import { Button, ButtonProps, Center, Divider, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import { Link, useLocation } from 'wouter'
import ManageSettingsIcon from '@material-design-icons/svg/sharp/manage_accounts.svg?react'
import AdminPanelSettingsIcon from '@material-design-icons/svg/sharp/admin_panel_settings.svg?react'
import LogoutIcon from '@material-design-icons/svg/sharp/logout.svg?react'
import StorageIcon from '@material-design-icons/svg/sharp/storage.svg?react'

import { memo } from 'react'

import { logout } from '@/features/auth/actions'
import { useAppDispatch } from '@/hooks'
import { useWhoamiQuery } from '@/redux/api/workspace'

import DarkModeToggle from '@/components/DarkModeToggle'

interface UserProfileButtonProps {
  containerRef: React.RefObject<HTMLDivElement>
}

const UserProfileButtonInner = (props: UserProfileButtonProps & ButtonProps) => {
  const { children, ...buttonProps } = props
  const { data: profile } = useWhoamiQuery()

  const dispatch = useAppDispatch()
  const [_location, setLocation] = useLocation()
  const onLogout = async () => {
    await dispatch(logout())
    setLocation('/', { replace: true })
  }

  return (
    <Menu isLazy={true}>
      <MenuButton as={Button} {...buttonProps}>
        {children}
      </MenuButton>
      <Portal containerRef={props.containerRef}>
        <MenuList zIndex={2}>
          <MenuItem icon={<ManageSettingsIcon />} as={Link} to="/-/settings/user">
            Account Settings
          </MenuItem>
          {profile?.is_admin && (
            <MenuItem icon={<AdminPanelSettingsIcon />} as={Link} to="/-/settings/admin">
              Admin Settings
            </MenuItem>
          )}
          <Divider />
          <MenuItem icon={<StorageIcon />} as="a" href="/api/v2/api-docs/html" target="_blank" rel="noopener noreferrer">
            API Documentation
          </MenuItem>
          <Divider />
          <MenuItem icon={<LogoutIcon />} onClick={onLogout}>
            Logout
          </MenuItem>
          <Center margin={2}>
            <DarkModeToggle />
          </Center>
          <MenuItem
            as="a"
            href="https://www.mayhem.security/saas-license-agreement"
            fontSize="xs"
            target="_blank"
            rel="noopener noreferrer"
            data-selenium-id="footer-docs"
          >
            Terms of Service
          </MenuItem>
          <MenuItem
            as="a"
            href="https://mayhem.security"
            target="_blank"
            rel="noopener noreferrer"
            data-selenium-id="forAllSecureLnk"
            fontSize="xs"
            textAlign="center"
          >
            © ForAllSecure {new Date().getFullYear()}, All Rights reserved.
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export const UserProfileButton = memo(UserProfileButtonInner)
