import { useEffect } from 'react'

import { Card, CardBody, CardHeader, Heading, Skeleton, Stack } from '@chakra-ui/react'

import { UpdateInfoForm } from './UpdateInfoForm'

import { useWhoamiQuery } from '@/redux/api/workspace'

import UserService from '@/features/auth/UserService'
import { getWorkspaceSettingsPageTitle } from '@/titles'
import { useAppDispatch } from '@/hooks'

export function UserSettingsPersonalInfoPage() {
  const dispatch = useAppDispatch()

  const { isLoading: isFetchingUser, data: profile } = useWhoamiQuery()
  const currentUserSlug = profile?.slug

  useEffect(() => {
    document.title = getWorkspaceSettingsPageTitle(currentUserSlug || '', 'My Details')
  }, [dispatch, currentUserSlug])

  // Keycloak-sourced accounts are not treated the same as generic OAuth
  // accounts (Google/Okta/GitHub native integration).
  const keycloakAccountUrl = UserService.accountManagementUrl()

  if (isFetchingUser) {
    return <Skeleton height={80} />
  }

  return (
    <Stack>
      <Card mb={8}>
        <CardHeader justifyContent="center">
          <Heading variant="cardHeading" m={0}>
            Your Details
          </Heading>
        </CardHeader>
        <CardBody p={8}>
          <UpdateInfoForm keycloakAccountUrl={keycloakAccountUrl || ''} />
        </CardBody>
      </Card>
    </Stack>
  )
}
