import { HStack, IconButton } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { useGetRunQuery } from '../../redux/api/runs'

import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

export interface RunBreadcrumbProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export const RunBreadcrumb = ({ workspaceSlug, projectSlug, targetSlug, runNumber }: RunBreadcrumbProps) => {
  const { data: run, isLoading: isRunLoading } = useGetRunQuery({
    owner: workspaceSlug,
    projectSlug: projectSlug,
    targetSlug: targetSlug,
    runNumber
  })
  const projectName = run?.project_name
  const targetName = run?.target_name

  const [_location, setLocation] = useLocation()

  const previous_run_number = run?.previous_run?.run_number
  const next_run_number = run?.next_run_number

  const navigateToRun = (toRunNumber: number) => {
    setLocation(`/${workspaceSlug}/${projectSlug}/${targetSlug}/${toRunNumber}`)
  }

  return (
    <HStack alignItems="center">
      <MayhemBreadcrumb
        isLoading={isRunLoading}
        items={[
          { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
          { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
          {
            text: targetName!,
            path: `/${workspaceSlug}/${projectSlug}/${targetSlug}`,
            isBadge: true,
            badgeTooltip: 'Target',
            hideSeparator: true
          },
          {
            text: `Run ${runNumber}`
          }
        ]}
      />
      <HStack>
        {previous_run_number && (
          <IconButton variant="outline" icon={<ChevronLeftIcon />} onClick={() => navigateToRun(previous_run_number)} aria-label="Previous Run" />
        )}
        {next_run_number && (
          <IconButton variant="outline" icon={<ChevronRightIcon />} onClick={() => navigateToRun(next_run_number)} aria-label="Next Run" />
        )}
      </HStack>
    </HStack>
  )
}
