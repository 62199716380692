import { ComponentStyleConfig } from '@chakra-ui/react'

export const Table: ComponentStyleConfig = {
  variants: {
    simple: {
      borderBottomWidth: '0px',
      th: {
        borderColor: 'inherit',
        textTransform: 'none',
        fontWeight: 'semibold',
        fontSize: 'sm'
      },
      td: {
        borderColor: 'inherit'
      },
      tr: {
        // the following ensures no extra bottom border shows
        '&:nth-last-of-type(2)': {
          td: {
            borderBottomWidth: '0px'
          }
        },
        '&:last-of-type': {
          td: {
            borderBottomWidth: '0px'
          }
        }
      }
    }
  }
}
