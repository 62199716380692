import isEmail from 'validator/lib/isEmail'

export function getCurrentUserInfo(state: RootState): ApiUserType | Record<string, never> {
  return state.auth.user || {}
}

export const validateEmail = (email: string): string | undefined => (!email || !isEmail(email) ? 'Invalid email address' : undefined)

import { ApiUserType } from '../admin/types'
import { RootState } from '../../redux/store'
