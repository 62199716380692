import { useEffect } from 'react'

import { Badge, Heading } from '@chakra-ui/react'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { TargetOverviewPage } from './TargetOverviewPage'

import { TargetSettingsGeneralPage } from './TargetSettingsGeneralPage'

import { DataTab, DataTabs } from '@/components/DataTabs'
import { Page } from '@/components/Page'
import { useAppSelector } from '@/hooks'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery } from '@/redux/api/projects'
import { useGetTargetQuery } from '@/redux/api/targets'
import { getTargetPageTitle } from '@/titles'

import { getCurrentUserInfo } from '@/features/auth/utils'
import { WorkersFullNotice } from '@/features/runs/WorkersFullNotice'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
}

export function TargetPage({ workspaceSlug, projectSlug, targetSlug }: Props) {
  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspaceSlug,
    projectSlug,
    userSlug
  })
  const isAdmin = isMayhemAdmin || (!projectMemberPermissionIsLoading && projectPermission && projectPermission.permission === 'ADMIN')

  const {
    data: targetData,
    isLoading: isTargetLoading,
    error,
    isError
  } = useGetTargetQuery({
    owner: workspaceSlug,
    projectSlug,
    targetSlug
  })
  const { target_name: targetName, is_deleted: isDeleted, type: targetType } = targetData || {}
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({ owner: workspaceSlug, projectSlug })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  useEffect(() => {
    if (workspaceName && projectName && targetName) {
      document.title = getTargetPageTitle(workspaceName, projectName, targetName)
    }
  }, [workspaceName, projectName, targetName])

  if ((isError && error) || (targetData && isDeleted)) {
    const goBackLocation = `/${workspaceSlug}`
    if (isDeleted) {
      return <ErrorPage errorCode={404} errorMessage="The specified target is no longer available" goBackLocation={goBackLocation} />
    }

    if (error && 'status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified target" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the target" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the target" goBackLocation={goBackLocation} />
  }

  const readTabs: DataTab[] = [
    {
      label: 'Overview',
      content: (
        <TargetOverviewPage
          workspaceSlug={workspaceSlug}
          projectSlug={projectSlug}
          targetSlug={targetSlug}
          targetType={targetType as 'api' | 'code'}
        />
      ),
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}`
    }
  ]

  const adminTabs = [
    {
      label: 'Settings',
      content: <TargetSettingsGeneralPage workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} targetName={targetName!} />,
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}/-/settings/general`
    }
  ]

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading || isTargetLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            {
              text: targetName!,
              isBadge: true,
              badgeTooltip: 'Target',
              hideSeparator: true
            }
          ]}
        />
      }
      isLoading={isTargetLoading}
    >
      <WorkersFullNotice />
      <Heading mb={8}>
        {targetName}
        <Badge ml={4} key={targetType} variant="outline">
          {targetType}
        </Badge>
      </Heading>
      <DataTabs isLazy variant="line" width="full" tabs={isAdmin ? readTabs.concat(adminTabs) : readTabs} />
    </Page>
  )
}
