import { Route, RouteProps, DefaultParams, Path, Redirect } from 'wouter'

import { useAppSelector } from '../../hooks'
import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import UserService from './UserService'

export function AdminRoute<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath> & { fallbackPath?: string }
): JSX.Element {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const isAdmin = useAppSelector((state) => state.auth.user.isAdmin)
  const isFetching = useAppSelector((state) => state.auth.isFetching)

  if (isFetching) {
    return <MayhemPageLoader />
  }

  if (isAuthenticated && isAdmin) {
    return <Route {...props} />
  }

  UserService.doLogin()

  return <Redirect to={props.fallbackPath || '/'} />
}
