import { useEffect } from 'react'
import { Stack } from '@chakra-ui/react'

import { TeamMembersCard } from './TeamMembersCard'

import { AddMembersCard } from './AddMembersCard'

import { getTeamPageTitle } from '@/titles'

import { Page } from '@/components/Page'

import { usePermissions } from '@/hooks'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

type Props = {
  workspaceSlug: string
  team: string
}

export function TeamPage({ workspaceSlug, team: teamSlug }: Props) {
  const { allowWrite: userCanAddMembersToTeam } = usePermissions(workspaceSlug)

  useEffect(() => {
    document.title = getTeamPageTitle(workspaceSlug, teamSlug)
  }, [workspaceSlug, teamSlug])

  return (
    <Page
      header={
        <MayhemBreadcrumb isLoading={false} items={[{ text: 'Workspace Settings', path: `/${workspaceSlug}/-/settings` }, { text: teamSlug }]} />
      }
    >
      <Stack spacing={8}>
        {userCanAddMembersToTeam && <AddMembersCard workspaceSlug={workspaceSlug} team={teamSlug} />}
        <TeamMembersCard workspaceSlug={workspaceSlug} teamSlug={teamSlug} />
      </Stack>
    </Page>
  )
}
