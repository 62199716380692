import { ComponentStyleConfig } from '@chakra-ui/react'

import { codeBlockAnatomy } from '../../components/CodeBlock/anatomy'

export const CodeBlock: ComponentStyleConfig = {
  parts: codeBlockAnatomy.keys,
  baseStyle: () => ({
    code: {},
    button: {
      bg: 'transparent',
      fontSize: 'xs',
      textAlign: 'center'
    },
    copyToClipboard: {
      color: 'row-button-text',
      _hover: { backgroundColor: 'row-button-hover-bg' },
      _active: { backgroundColor: 'row-button-active-bg' },
      position: 'absolute',
      right: '1',
      top: '1',
      minWidth: '6',
      height: '6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 'md'
    }
  })
}
