import { ComponentStyleConfig } from '@chakra-ui/react'

export const CardRow: ComponentStyleConfig = {
  baseStyle: () => ({
    borderTop: '0.5px solid',
    borderColor: 'inherit',
    cursor: 'pointer',
    _first: { borderTopWidth: '0px' },
    _last: { borderBottomWidth: '0px' },
    _hover: {
      background: 'hoverBg'
    },
    _active: {
      background: 'activeBg'
    },
    _selected: {
      background: 'selectedBg'
    },
    _disabled: { bg: 'none', cursor: 'unset' }
  })
}
