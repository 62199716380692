import { Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, SkeletonText, Tooltip } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { ChevronRightIcon } from '@chakra-ui/icons'

import { TruncatedTextWithTooltip } from './TruncatedTextWithTooltip'

interface BreadcrumbInfo {
  text: string
  path?: string
  hideSeparator?: boolean
  isBadge?: boolean
  badgeTooltip?: string
}

interface Props {
  items: BreadcrumbInfo[]
  isLoading: boolean
}

export const MayhemBreadcrumb = ({ items, isLoading }: Props) => {
  if (isLoading) {
    return <SkeletonText noOfLines={1} width="sm" />
  }
  return (
    <Breadcrumb separator="" spacing={0}>
      {items.map(({ path, text, hideSeparator, isBadge, badgeTooltip }, index) => (
        <BreadcrumbItem key={index} isCurrentPage={index === items.length - 1}>
          <BreadcrumbLink as={path ? WouterLink : undefined} to={path}>
            {isBadge ? (
              <Tooltip label={badgeTooltip} hasArrow>
                <Badge variant="breadcrumb">{text}</Badge>
              </Tooltip>
            ) : (
              <TruncatedTextWithTooltip text={text} />
            )}
          </BreadcrumbLink>
          {hideSeparator || index === items.length - 1 ? <Box mx="4px" /> : <Icon as={ChevronRightIcon} color="gray-text" boxSize="18px" mx={1} />}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}
