import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import UserService from '../../features/auth/UserService'

import { RootState } from '../store'
import { getCookie } from '../../cookies'

export const AUTOREFRESH_INTERVAL = 5000
export const DEFAULT_PER_PAGE = 15
export const DEFAULT_PER_PAGE_OPTIONS = [15, 30, 45]

const mayhemApiBase = fetchBaseQuery({
  baseUrl: import.meta.env.VITEST === 'true' ? new URL('/', location.origin).href : '/',
  credentials: 'same-origin',
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState
    if (state.config.apiVersion) {
      headers.set('X-Mayhem-Client-Version', state.config.apiVersion)
    }

    // Ignore this block when running in test mode. We do not unit
    // test anything that requires keycloak at time of writing.
    if (import.meta.env.VITEST !== 'true') {
      // We cannot get a valid keycloak state from UserService unless we know
      // whether keycloak has been initialized or not. We cannot initialize
      // keycloak in here since keycloak state must only be initialized once for
      // the entire app. It is assumed that this initialization is handled at the
      // top level of the App itself.
      await UserService.initKeycloak()
    }

    if (UserService.isLoggedInToKeycloak()) {
      // This is usually a no-op if user's token is still valid - otherwise
      // this will automatically refresh the token before using it in the header.
      await UserService.updateKeycloakToken()
      if (UserService.getKeycloakBearerToken() !== undefined) {
        headers.set('Authorization', `Bearer ${UserService.getKeycloakBearerToken()}`)
      }
    }

    return headers
  }
})

const mayhemApiBaseWithCsrfAndRedirectOn401: typeof mayhemApiBase = async (arg, ...args) => {
  if (typeof arg === 'object' && typeof arg.body === 'object' && ['POST', 'PUT'].indexOf(arg.method as string) !== -1) {
    arg.body.token = getCookie('token')
  }
  const result = await mayhemApiBase(arg, ...args)

  // if the server responds with a 401 response, redirect to the sign-in page
  // with the referrer url set
  if (result.error && result.error.status === 401) {
    UserService.doLogin()
  }

  return result
}

export const mayhemApi = createApi({
  reducerPath: 'mayhemApi',
  baseQuery: mayhemApiBaseWithCsrfAndRedirectOn401,
  endpoints: () => ({})
})
