import { Box, Button, Flex, Heading, ListItem, Skeleton, Stack, StackDivider, Text, UnorderedList } from '@chakra-ui/react'
import { DownloadIcon } from '@chakra-ui/icons'
import React from 'react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

import { getOverrideDisplay } from './utils'

export function MayhemfileDisplay({
  description,
  mayhemfile,
  mayhemfileUrl,
  envVars = {},
  overrides = {},
  isFetching,
  showButton
}: {
  description: string
  mayhemfile: string | undefined
  mayhemfileUrl: string
  envVars?: Record<string, unknown>
  overrides?: Record<string, unknown>
  isFetching?: boolean
  showButton: boolean
}): JSX.Element {
  const envVarsKeys = Object.keys(envVars || {})
  const overridesKeys = Object.keys(overrides || {})

  return (
    <Skeleton isLoaded={!isFetching}>
      <Stack spacing={8} divider={<StackDivider />}>
        <Box>
          <Text>{description}</Text>
          <CodeBlock>{mayhemfile || ''}</CodeBlock>
          {showButton && (
            <Flex justifyContent="flex-end">
              <a href={mayhemfileUrl} download>
                <Button leftIcon={<DownloadIcon boxSize={6} />}>Download Mayhemfile</Button>
              </a>
            </Flex>
          )}
        </Box>
        {envVarsKeys.length > 0 && (
          <React.Fragment>
            <Heading size="md">Environment Variables Used</Heading>
            <UnorderedList>
              {envVarsKeys.map((envVar: string) => (
                <ListItem key={envVar}>
                  <Text as="b">{envVar}</Text>: {envVars[envVar] as string}
                </ListItem>
              ))}
            </UnorderedList>
          </React.Fragment>
        )}
        {overridesKeys.length > 0 && (
          <React.Fragment>
            <Heading size="md">Overrides Used</Heading>
            <UnorderedList>
              {overridesKeys.map((overrideKey) => {
                return (
                  <ListItem key={overrideKey}>
                    <Text as="b">{overrideKey}</Text> - {getOverrideDisplay(overrideKey, overrides[overrideKey])}
                  </ListItem>
                )
              })}
            </UnorderedList>
          </React.Fragment>
        )}
      </Stack>
    </Skeleton>
  )
}
