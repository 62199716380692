import { useEffect } from 'react'

import { Card, CardBody, Heading, Stack } from '@chakra-ui/react'

import { MayhemStats } from '../../components/MayhemStats'
import { getProjectRunsPageTitle } from '../../titles'

import { useInterval, useQuery } from '../../hooks'

import { ProjectRunsList } from '../project-runs/ProjectRunsList'

import { useGetProjectQuery, useGetRunStatsByProjectQuery } from '../../redux/api/projects'
import { formatNumber } from '../../util/numbers'

import { Page } from '@/components/Page'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectRunsPage({ workspaceSlug, projectSlug }: Props) {
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({
    owner: workspaceSlug,
    projectSlug
  })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  const targetFilter = useQuery().get('target') || undefined

  const {
    isLoading: isRunsStatsLoading,
    data: { active = 0, count = 0 } = {},
    refetch
  } = useGetRunStatsByProjectQuery({ owner: workspaceSlug, projectSlug, target: targetFilter })

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectRunsPageTitle(workspaceName, projectName)
    }
  }, [workspaceName, projectName])

  useInterval({
    action: refetch,
    deps: [refetch]
  })

  const stats = [
    { label: `Active Run${active !== 1 ? 's' : ''}`, value: formatNumber(active, { shorten: false }) },
    { label: `Total Run${count !== 1 ? 's' : ''}`, value: formatNumber(count, { shorten: false }) }
  ]

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            { text: 'Runs' }
          ]}
        />
      }
    >
      <Heading mb={8}>Runs</Heading>
      <Stack spacing={8}>
        <Card>
          <CardBody p={8}>
            <MayhemStats padded stats={stats} isFetching={isRunsStatsLoading} />
          </CardBody>
        </Card>
        <ProjectRunsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
      </Stack>
    </Page>
  )
}
