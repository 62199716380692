import { Redirect, Route, RouteProps, DefaultParams, Path } from 'wouter'

import { useAppSelector } from '../../hooks'
import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import UserService from './UserService'

export function PrivateMatch<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath>
): JSX.Element {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const isFetching = useAppSelector((state) => state.auth.isFetching)

  if (isFetching) {
    return <MayhemPageLoader />
  }

  if (isAuthenticated) {
    return <Route {...props} />
  }

  UserService.doLogin()

  return <Redirect to="/" />
}
