// These can be used for semantically naming colors, sizes, spacing, etc. if we want
export const semanticTokens = {
  sizes: {
    height: {
      table: {
        filters: {
          input: {
            default: 9
          },
          button: {
            default: 9
          }
        }
      }
    }
  },
  spacing: {
    gap: {
      table: {
        filters: { default: '0.6rem' }
      }
    },
    padding: {
      X: {
        table: {
          filters: {
            input: {
              default: '0.7rem'
            }
          }
        },
        card: {
          default: '2rem'
        }
      },
      Y: {
        table: {
          filters: {
            input: {
              default: '0.5rem'
            }
          }
        },
        card: {
          default: '1.5rem'
        }
      }
    }
  },
  colors: {
    sidebarBg: {
      default: 'gray.50',
      _dark: 'gray.950'
    },
    pageBg: {
      default: 'gray.50',
      _dark: 'gray.950'
    },

    // Override some chakra variables
    // to have global reach over basic variables
    // see https://github.com/chakra-ui/chakra-ui/blob/05f922f2fada732f04d93e13bd53434805f4d619/packages/theme/src/semantic-tokens.ts
    // note: for overriding Chakra's built in semantic tokens, you have to use the `_light` and `_dark` keys specifically (not `default` like usual)
    'chakra-body-bg': { _light: 'white', _dark: 'gray.900' },
    'chakra-body-text': { _light: 'gray.900', _dark: 'whiteAlpha.900' },
    'chakra-placeholder-color': { _light: 'gray.600', _dark: 'whiteAlpha.800' },

    // Semantic colors
    primary: { default: 'brand.500', _dark: 'brand.200' },
    faded: { default: 'blackAlpha.600', _dark: 'whiteAlpha.700' },
    bad: { default: 'bad.500', _dark: 'bad.400' },
    good: { default: 'success.500', _dark: 'success.400' },
    warning: { default: 'yellow.500', _dark: 'yellow.400' },
    error: { default: 'orange.500', _dark: 'orange.400' },
    severityHigh: { default: 'severityHigh.500', _dark: 'severityHigh.400' },
    severityMedium: { default: 'severityMedium.500', _dark: 'severityMedium.400' },
    severityLow: { default: 'severityLow.600', _dark: 'severityLow.400' },
    graphColor1: { default: '#EC000E', _dark: '#D60011' },
    graphColor2: { default: '#FFBB44', _dark: '#E7A941' },
    graphColor3: { default: '#DCFF10', _dark: '#C7E612' },
    graphColor4: { default: '#F26A8D', _dark: '#DB6083' },
    graphColor5: { default: '#2BB7E3', _dark: '#28A5D0' },
    graphColor6: { default: '#1B998B', _dark: '#1A8A81' },
    subtleBg: { default: 'gray.50', _dark: 'gray.800' },

    chartSeries1: { default: 'brand.600', _dark: 'brand.500' },
    chartSeries2: { default: 'brand.400', _dark: 'brand.300' },
    chartAxis: { default: 'gray.600', _dark: 'gray.400' },

    code: { default: 'brand.700', _dark: 'brand.200' },

    'subnav-section-header': { default: 'gray.500', _dark: 'gray.700' },

    accent: {
      default: 'brand.500',
      _dark: 'brand.200'
    },
    'bg-accent': 'body-dark',
    'bg-accent-subtle': 'brand.800',
    'on-accent': 'white',
    'on-accent-muted': 'brand.50',
    'on-accent-subtle': 'brand.100',
    mutedAccent: {
      default: 'brand.600',
      _dark: 'brand.100'
    },

    hoverBg: {
      default: 'brand.50',
      _dark: 'gray.850'
    },
    hoverColor: {
      default: 'gray.900',
      _dark: 'white'
    },
    // active = when button/etc is being clicked
    activeBg: {
      default: 'brand.200',
      _dark: 'gray.700'
    },
    activeColor: {
      default: 'gray.900',
      _dark: 'white'
    },
    // selected = when indicating the currently selected page/preview
    selectedBg: {
      default: 'brand.100',
      _dark: 'gray.800'
    },
    labelBg: {
      default: 'brand.50',
      _dark: 'gray.700'
    },
    selectedColor: {
      default: 'gray.900',
      _dark: 'white'
    },
    purpleBg: {
      default: 'purple.50',
      _dark: 'gray.500'
    },
    purpleColor: {
      default: 'purple.500',
      _dark: 'gray.800'
    },
    'gray-text': {
      default: 'gray.600',
      _dark: 'gray.400'
    },
    'disabled-link-text': {
      default: 'brand.200',
      _dark: 'brand.100'
    },
    'circular-progress-track-color': {
      default: 'gray.100',
      _dark: 'whiteAlpha.200'
    },
    'collapsed-menu-bg': {
      default: 'brand.50',
      _dark: 'color-mix(in srgb, var(--chakra-colors-gray-900) 50%, transparent)'
    },
    'collapsed-menu-border': {
      default: 'brand.75',
      _dark: 'gray.900'
    },
    'collapsed-menu-divider': {
      default: 'gray.200',
      _dark: 'whiteAlpha.300'
    },
    'row-button-text': {
      default: 'brand.500',
      _dark: 'brand.300'
    },
    'row-button-hover-bg': {
      default: 'brand.100',
      _dark: 'whiteAlpha.200'
    },
    'row-button-active-bg': {
      default: 'brand.200',
      _dark: 'whiteAlpha.300'
    },
    'breadcrumb-badge-bg': {
      default: '#EBEEFF',
      _dark: 'whiteAlpha.200'
    }
  }
}
