import { ComponentStyleConfig } from '@chakra-ui/react'
import { breadcrumbAnatomy } from '@chakra-ui/anatomy'

export const Breadcrumb: ComponentStyleConfig = {
  parts: breadcrumbAnatomy.keys,
  baseStyle: {
    container: { lineHeight: '24px', ol: { flexWrap: 'wrap' } },
    link: {},
    item: { textColor: 'gray-text', '[aria-current=page]': { textColor: 'chakra-body-text' } },
    separator: {}
  }
}
