import { ApiUserType } from '../admin/types'
import { RECEIVE_ME, RECEIVE_KEYCLOAK, RECEIVE_JWT } from '../../redux/fetchMe'

import { AuthAction } from './actions'

export const userMapper = <T extends { slug?: string }>(_user: T): T & { userSlug?: string } => ({
  ..._user,
  userSlug: _user.slug
})

export type AuthState = {
  isAuthenticated: boolean
  isFetching: boolean | null
  logout: boolean
  user: ApiUserType | Record<string, never>
  keycloakUserId: string | undefined
  mdsbomJwtToken: string | undefined
}

export default function authReducer(
  state: AuthState = {
    isAuthenticated: false,
    isFetching: true,
    logout: false,
    user: {},
    keycloakUserId: undefined,
    mdsbomJwtToken: undefined
  },
  action: AuthAction
): AuthState {
  switch (action.type) {
    case RECEIVE_ME:
      return {
        ...state,
        user: userMapper({ ...action.user }),
        isAuthenticated: true,
        isFetching: false,
        logout: false
      }

    case RECEIVE_KEYCLOAK:
      return {
        ...state,
        keycloakUserId: action.keycloakUserId
      }

    case RECEIVE_JWT:
      return {
        ...state,
        mdsbomJwtToken: action.jwtToken
      }

    default:
      return state
  }
}
