import * as Sentry from '@sentry/browser'
import { UnknownAction } from 'redux'

import TagManager from 'react-gtm-module'

import { get } from '../../api'

import { AppThunk } from '../../redux/store'
import { IsomorphicFetchError } from '../../types'
import { UserSettingResponse } from '../admin/types'
import UserService from '../auth/UserService'

import { fetchMe } from '../../redux/fetchMe'
import { getJwtToken } from '../auth/actions'

const isProduction = import.meta.env.PROD

export const SET_API_INFO = 'SET_API_INFO'

export type ConfigAction = SetApiInfo | SetMayhemInfo | SetKeycloakInfo

export type APIResponse = {
  offline?: boolean
  environment?: string
  version?: string
  public?: boolean
  name?: string
}

export type SetApiInfo = {
  type: typeof SET_API_INFO
  api: APIResponse
}

export function setApiInfo(api: APIResponse): SetApiInfo {
  return {
    type: SET_API_INFO,
    api
  }
}

export function fetchApiInfo(): AppThunk {
  return async (dispatch) => {
    try {
      const api: APIResponse = await get('/api/')
      dispatch(setApiInfo(api))

      if (api.offline || !isProduction || window.location.hostname === 'localhost') {
        console.log(`Sentry telemetry disabled in environment NODE_ENV - ${process.env.NODE_ENV}.
        Sentry events are visible at the debug level in the dev console.`)
      }

      // DSN generated from here: https://forallsecure-inc.sentry.io/settings/projects/mayhem-ui/keys/
      Sentry.init({
        dsn: 'https://423fc11a42304ab3ba857910ec1f4938@o62063.ingest.sentry.io/1540195',
        environment: api.environment,
        release: api.version,
        beforeSend: (event, hint) => {
          if (api.offline || !isProduction || window.location.hostname === 'localhost') {
            console.debug('Sentry event intercepted', hint.originalException?.toString() || hint.syntheticException?.message || event)
            // swallow the event - it will not be forwarded to sentry.io
            return null
          }
          return event
        }
      })

      if (!api.offline && api.environment !== 'unknown' && window.location.hostname !== 'localhost' && isProduction) {
        // Start Google Tag Manager Init
        const tagManagerArgs = {
          gtmId: 'GTM-KBD53D2'
        }

        TagManager.initialize(tagManagerArgs)
      }
    } catch (err) {
      if ((err as IsomorphicFetchError).type !== 'APIWarning') {
        throw err
      }
    }
  }
}

export const SET_MAYHEM_INFO = 'SET_MAYHEM_INFO'

export type MayhemInfo = { mayhemVersion: string }

export type SetMayhemInfo = {
  type: typeof SET_MAYHEM_INFO
  mayhem: MayhemInfo
}

function setMayhemInfo(mayhem: MayhemInfo): SetMayhemInfo {
  return {
    type: SET_MAYHEM_INFO,
    mayhem
  }
}

export function fetchMayhemInfo(): AppThunk {
  return (dispatch) => {
    if (window.location.hostname === 'localhost') {
      dispatch(setMayhemInfo({ mayhemVersion: '' }))
      return null
    }

    /** This file is populated externally by the mchart helm chart
     * It contains the mayhem release ( the version of the helm chart ) which
     * is the public facing version of mayhem. */
    return get<MayhemInfo>('/info/version.json').then((mayhem) => {
      dispatch(setMayhemInfo(mayhem))
    })
  }
}

export const SET_KEYCLOAK_INFO = 'SET_KEYCLOAK_INFO'

export type KeycloakInfo = { userSettings: UserSettingResponse; isInitialized: boolean }

export type SetKeycloakInfo = {
  type: typeof SET_KEYCLOAK_INFO
  info: KeycloakInfo
}

export function setKeycloakInfo(info: KeycloakInfo): SetKeycloakInfo {
  return {
    type: SET_KEYCLOAK_INFO,
    info
  }
}

/**
 * Initializes local keycloak state (if Keycloak is enabled on the API) and checks
 * to see if a user is currently signed in.
 */
export function checkAuthentication(): AppThunk {
  return async (dispatch) => {
    const userSetting: UserSettingResponse = await get('/api/v2/user-setting')
    await UserService.initKeycloak()
    dispatch(setKeycloakInfo({ userSettings: userSetting, isInitialized: true }))
    dispatch(fetchMe() as unknown as UnknownAction)
    dispatch(getJwtToken())
  }
}
